import { useTranslation } from "react-i18next";
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";

export const TasksSelectInput = ({ onChange, value, tasks, labelKey }) => {
  const { t } = useTranslation();
  return (
    <FormControl sx={{ my: 1, width: "100%" }}>
      <InputLabel htmlFor="service">{t(labelKey)}</InputLabel>
      <Select
        name="task"
        value={value}
        id="select"
        label={t("appointmentNotes.task")}
        onChange={onChange}
      >
        {tasks.short && tasks.short.length > 0 && (
          <ListSubheader>{t("appointmentNotes.taskShort")}</ListSubheader>
        )}
        {tasks.short &&
          tasks.short.length > 0 &&
          tasks.short.map((value) => (
            <MenuItem key={value.id} value={value.id}>
              {value.name}
            </MenuItem>
          ))}
        {tasks.short && tasks.short.length > 0 && (
          <ListSubheader>{t("appointmentNotes.taskLong")}</ListSubheader>
        )}
        {tasks.long &&
          tasks.long.length > 0 &&
          tasks.long.map((value) => (
            <MenuItem key={value.id} value={value.id}>
              {value.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default TasksSelectInput;
