import { createTheme } from "@mui/material";
import { indigo, purple } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: indigo,
    secondary: purple,
  },
  typography: {
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2.5rem",
    },
    h3: {
      fontSize: "2rem",
    },
    h4: {
      fontSize: "1.75rem",
    },
    h5: {
      fontSize: "1.5rem",
    },
    h6: {
      fontSize: "1.25rem",
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiTable: {
      defaultProps: {
        stickyHeader: true,
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          top: theme.spacing(8),
        }),
      },
    },
  },
});

export default theme;
