import React, { useContext, createContext } from "react";
import { authenticationTokenExpiresIn, getAuthenticationToken } from "../auth";

const AuthenticationTokenContext = createContext();

export function useAuthenticationToken() {
  return useContext(AuthenticationTokenContext);
}

export function AuthenticationTokenProvider({ children }) {
  const [token, setToken] = React.useState(getAuthenticationToken());
  const value = React.useMemo(() => [token, setToken], [token, setToken]);

  React.useEffect(
    function removeExpiredToken() {
      if (token == null) {
        return;
      }

      const timeoutId = setTimeout(() => {
        setToken(null);
      }, authenticationTokenExpiresIn(token));

      return function () {
        clearTimeout(timeoutId);
      };
    },
    [token],
  );

  return (
    <AuthenticationTokenContext.Provider value={value}>
      {children}
    </AuthenticationTokenContext.Provider>
  );
}
