import React, { createContext, useState, useContext } from "react";

const StayLoggedInContext = createContext();

export const StayLoggedInProvider = ({ children }) => {
  const [stayLoggedIn, setStayLoggedIn] = useState(false);

  return (
    <StayLoggedInContext.Provider value={{ stayLoggedIn, setStayLoggedIn }}>
      {children}
    </StayLoggedInContext.Provider>
  );
};

export const useStayLoggedIn = () => useContext(StayLoggedInContext);

export { StayLoggedInContext };
