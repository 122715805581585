import React, { useState, useEffect, useMemo } from "react";
import { useLoaderData } from "react-router-dom";
import {
  Tab,
  Tabs,
  Box,
  Grid2,
  TextField,
  Divider,
  Button,
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { useTranslation } from "react-i18next";
import DocumentsTable from "../components/DocumentsTable";
import AppointmentNotesTabContent from "../components/client/AppointmentNotesTabContent";
import {
  ClientInfoCard,
  TreatmentTypeCard,
  RiskAllergyCard,
  AppointmentHistoryCard,
  LegalGuardianCard,
  NextAppointmentCard,
} from "../components/client/cards";
import {
  WizardProvider,
  useWizardContext,
} from "../components/client/AppointmentWizardContext";
import AppointmentWizard from "../components/client/AppointmentWizard";
import TimerWidget from "../components/client/TimerWidget";
import ClientAvatar from "../components/client/ClientAvatar";
import { useWizardSteps } from "../components/client/hooks/useWizardSteps";
import * as api from "../services/api";

export async function loader({ params }) {
  const controller = new AbortController();
  const signal = controller.signal;

  const res = await api.loadClientData({ id: params.id }, { signal });
  if (!res.data?.client) {
    throw new Response("Not Found", { status: 404 });
  }

  const [legalGuardiansRes, treatmentTypesRes, riskAllergiesRes] =
    await Promise.all([
      res.data.client.legal_guardian_id
        ? api.loadLegalGuardians({ client_id: params.id }, { signal })
        : Promise.resolve({ data: { legalGuardians: [] } }),
      api.loadTreatmentTypes({ client_id: params.id }, { signal }),
      api.loadRiskAllergies({ client_id: params.id }, { signal }),
    ]);

  return {
    client: res.data.client,
    legalGuardians: legalGuardiansRes.data?.legalGuardians || [],
    treatmentTypes: treatmentTypesRes.data?.treatmentTypes || [],
    riskAllergies: riskAllergiesRes.data?.riskAllergies || [],
  };
}

function ClientContent() {
  const { t } = useTranslation();
  const loaderData = useLoaderData();
  const { client, legalGuardians, treatmentTypes, riskAllergies } = loaderData;
  const [tabValue, setTabValue] = useState("1");
  const { nurseNotes, setNurseNotes, setClientData } = useWizardContext();
  const { activeStep, handleProgressStep, getStepButtonLabel } =
    useWizardSteps();

  useEffect(() => {
    if (client?.id) {
      setClientData(loaderData);
    }

    return () => {
      setClientData(null);
      setNurseNotes("");
    };
  }, [client?.id]);

  const handleNotesChange = (event) => {
    setNurseNotes(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const cardStyle = useMemo(
    () => ({
      padding: 2,
      marginBottom: 2,
      width: 300,
      height: 300,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }),
    [],
  );

  const isBirthdayToday = () => {
    if (!client?.birthdate) return false;
    const today = new Date();
    const birthDate = new Date(client.birthdate);
    return (
      today.getDate() === birthDate.getDate() &&
      today.getMonth() === birthDate.getMonth()
    );
  };

  if (!client) {
    return <div>{t("client.notFound")}</div>;
  }

  return (
    <>
      <ClientAvatar client={client} t={t} isBirthdayToday={isBirthdayToday()} />

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="client-tabs"
        >
          <Tab label={t("client.tabs.overview")} value="1" />
          <Tab label={t("client.tabs.appointmentHistory")} value="2" />
          <Tab label={t("client.tabs.documents")} value="3" />
          <Tab label={t("client.tabs.appointmentNotes")} value="4" />
        </Tabs>
      </Box>

      <TabContext value={tabValue}>
        <TabPanel value="1">
          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            <Box>
              <Grid2 container spacing={2}>
                <Grid2 xs={12} sm={6} md={4} lg={3}>
                  <ClientInfoCard client={client} sx={cardStyle} />
                </Grid2>

                {treatmentTypes.length > 0 && (
                  <Grid2 xs={12} sm={6} md={4} lg={3}>
                    <TreatmentTypeCard
                      treatmentTypes={treatmentTypes}
                      sx={cardStyle}
                    />
                  </Grid2>
                )}

                {riskAllergies.length > 0 && (
                  <Grid2 xs={12} sm={6} md={4} lg={3}>
                    <RiskAllergyCard
                      riskAllergies={riskAllergies}
                      sx={cardStyle}
                    />
                  </Grid2>
                )}

                {legalGuardians.length > 0 && (
                  <Grid2 xs={12} sm={6} md={4} lg={3}>
                    <LegalGuardianCard
                      legalGuardians={legalGuardians}
                      sx={cardStyle}
                    />
                  </Grid2>
                )}

                <Grid2 xs={12} sm={6} md={4} lg={3}>
                  <NextAppointmentCard clientId={client?.id} />
                </Grid2>
              </Grid2>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Box sx={{ maxWidth: "500px" }}>
              <TextField
                label={t("client.notesForNurse")}
                multiline
                fullWidth
                value={nurseNotes}
                onChange={handleNotesChange}
                rows={4}
              />
            </Box>

            <TimerWidget isActive={activeStep >= 1} activeStep={activeStep} />

            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleProgressStep}
              >
                {getStepButtonLabel()}
              </Button>
            </Box>
          </Box>
        </TabPanel>

        <TabPanel value="2">
          <AppointmentHistoryCard id={client?.id} />
        </TabPanel>

        <TabPanel value="3">
          <p>{t("client.documentsDescription")}</p>
          <DocumentsTable documents={[]} />
        </TabPanel>

        <TabPanel value="4">
          <AppointmentNotesTabContent nurseNotes={nurseNotes} />
        </TabPanel>
      </TabContext>

      <AppointmentWizard />
    </>
  );
}

export default function Client() {
  return (
    <WizardProvider>
      <ClientContent />
    </WizardProvider>
  );
}
