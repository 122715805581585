import "@event-calendar/core/index.css";
import React, { useState, useEffect } from "react";
import { useLoaderData, useRevalidator } from "react-router-dom";
import {
  Button,
  TextField,
  Box,
  Typography,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";

import { status } from "shared/src/appointment.mjs";
import * as d from "shared/src/date.mjs";

import * as api from "../services/api";
import * as f from "../utils/formatter";
import * as ws from "../utils/ws";

import { NewReservationSuggestionDialog } from "../components/new-reservation-dialog";

const LOCATION_ID = "4c03478c-e8c1-4052-b3d1-c5b5a34bfc42";

export async function loader() {
  const now = new Date();
  const from = d.toString(d.startOfDay(now));
  const to = d.toString(d.endOfDay(now));

  return api.loadAppointments({
    filter: {
      start: { gte: from, lt: to },
      location_id: { eq: LOCATION_ID },
    },
    order: {
      asc: "start",
    },
  });
}

export default function ReceptionDashboard() {
  const [reservationDialogOptions, setReservationDialogOptions] =
    useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const loaderData = useLoaderData();
  const appointments = loaderData.data.appointments.data;
  const revalidator = useRevalidator();

  useEffect(() => {
    async function refresh(msg) {
      if (msg.type === "appointment_changed") {
        revalidator.revalidate();
      }
    }

    ws.subscribe(refresh);

    return () => {
      ws.unsubscribe(refresh);
    };
  }, []);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1>Dashboard Recepce</h1>
        <div style={{ display: "flex", gap: "1rem" }}>
          <Button
            variant="contained"
            onClick={() =>
              setReservationDialogOptions({
                close: () => setReservationDialogOptions(null),
                showAdhocInput: true,
              })
            }
          >
            Nová rezervace
          </Button>
        </div>
      </div>

      {reservationDialogOptions && (
        <NewReservationSuggestionDialog {...reservationDialogOptions} />
      )}

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "2",
          gridTemplateRows: "2",
          gridTemplateAreas: `"booked treated" "checkedIn treated"`,
          width: "100%",
          gap: "36px",
        }}
      >
        <div style={{ gridArea: "booked" }}>
          <BookedClientList
            appointments={appointments}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarOpen={setSnackbarOpen}
          />
        </div>
        <div style={{ gridArea: "checkedIn" }}>
          <CheckedInClientList appointments={appointments} />
        </div>
        <div style={{ gridArea: "treated" }}>
          <TreatedClientList appointments={appointments} />
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

function BookedClient({ appointment, setSnackbarMessage, setSnackbarOpen }) {
  const delayedBy = appointmentDelayedBy(appointment.start);
  const [openConfirmationModal, setOpenConfirmationModal] =
    React.useState(false);
  const handleOpenConfirmationModal = () => setOpenConfirmationModal(true);
  const handleCloseConfirmationModal = () => setOpenConfirmationModal(false);

  return (
    <>
      <div style={{ border: "1px solid black", padding: "0.5rem 1rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
            textAlign: "left",
          }}
        >
          <span style={{ flex: "0 0 50%", fontWeight: "bold" }}>
            <a href={"/clients/" + appointment.client.id}>
              {f.fullName(appointment.client)}
            </a>
          </span>
          <div style={{ flex: "1 0" }}>{f.time24(appointment.start)}</div>
          <span style={{ flex: "1 0" }}>
            {delayedBy.hours}:{delayedBy.minutes}
          </span>
          <span style={{ flex: "1 0", textAlign: "right" }}>
            <Button variant="contained" onClick={handleOpenConfirmationModal}>
              +
            </Button>
          </span>
        </div>
        <div>
          <strong>Úkon:</strong> {appointment.service.name}
        </div>
      </div>
      <ConfirmationModal
        appointment={appointment}
        handleCloseConfirmationModal={handleCloseConfirmationModal}
        openConfirmationModal={openConfirmationModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarOpen={setSnackbarOpen}
      />
    </>
  );
}

function CheckedInClient({ appointment }) {
  let checkedInList = appointment.audit.data.filter(
    (appointment) =>
      appointment.changed_fields?.status_id === String(status.CONFIRMED),
  );

  if (checkedInList.length === 0) {
    checkedInList = appointment.audit.data.filter(
      (appointment) =>
        appointment.changed_fields?.status_id === String(status.Waiting),
    );
  }

  const delayedBy = appointmentDelayedBy(appointment.start);

  const checkedInTime = getCheckedInTime(
    checkedInList[checkedInList.length - 1],
    appointment,
  );

  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
        border: "1px solid black",
        alignItems: "center",
        padding: "0.5rem 1rem",
      }}
    >
      <span style={{ flex: "0 0 30%", fontWeight: "bold" }}>
        <a href={"/clients/" + appointment.client.id}>
          {f.fullName(appointment.client)}
        </a>
        {!appointment.calendar_id && (
          <div>
            <strong>Ad hoc</strong>
          </div>
        )}
      </span>
      <span style={{ flex: "1 0" }}>{f.time24(appointment.start)}</span>
      <span style={{ flex: "3 0" }}>{appointment.service.name}</span>
      <span style={{ flex: "3 0" }}>
        {!!Number(checkedInTime.hours) && checkedInTime.hours + " hod "}
        {checkedInTime.minutes} min
      </span>
      <span style={{ flex: "3 0" }}>
        {delayedBy.hours}:{delayedBy.minutes}
      </span>
      <span style={{ flex: "1 0" }}>
        <Button
          style={{
            visibility:
              appointment.status_id === status.WAITING ? "hidden" : "visible",
          }}
          variant="contained"
          onClick={() => {
            api.patchAppointment({
              id: appointment.id,
              status_id: status.WAITING,
            });
          }}
        >
          Vyřešeno
        </Button>
      </span>
      <span>
        <Button
          variant="contained"
          onClick={() => {
            api.patchAppointment({
              id: appointment.id,
              status_id: status.BOOKED,
            });
          }}
        >
          {"<<"}
        </Button>
      </span>
    </div>
  );
}

function getCheckedInTime(audit, appointment) {
  if (audit) {
    return appointmentDelayedBy(
      d.toString(d.parseUtcDateTime(audit.created_at)),
    );
  }
  return appointmentDelayedBy(appointment.start);
}

function TreatedClient({ appointment }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "black 1px solid",
        padding: "0.5rem 1rem",
        gap: "1rem",
      }}
    >
      <span style={{ fontWeight: "bold" }}>
        <a href={"/clients/" + appointment.client.id}>
          {f.fullName(appointment.client)}
        </a>
      </span>
      <Button variant="contained">Objednat</Button>
    </div>
  );
}

function BookedClientHeader() {
  return (
    <div
      style={{
        display: "flex",
        border: "1px solid black",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0.5rem 1rem",
        gap: "1rem",
        textAlign: "left",
      }}
    >
      <span style={{ flex: "0 0 50%", fontWeight: "bold" }}>Jméno klienta</span>
      <span style={{ flex: "1 0" }}>Start</span>
      <span style={{ flex: "1 0" }}>Zpoždění</span>
      <span style={{ flex: "1 0" }}>K řešení</span>
    </div>
  );
}

function BookedClientList({
  appointments,
  setSnackbarMessage,
  setSnackbarOpen,
}) {
  const [bookedClients, setBookedClients] = useState(appointments);
  const [searchTerm, setSearchTerm] = useState("");
  const [listLimit, setListLimit] = useState(15);
  useEffect(() => {
    if (searchTerm) {
      setBookedClients(
        searchAppointments(appointments, searchTerm, status.BOOKED),
      );
    } else {
      setBookedClients(
        appointments.filter(
          (appointment) => appointment.status_id === status.BOOKED,
        ),
      );
    }
  }, [appointments, searchTerm, listLimit]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
          <h2>Plánovaní klienti</h2>
          <TextField
            id="outlined-basic"
            label="Vyhledat klienta"
            variant="outlined"
            size="small"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </div>
        <FormControl sx={{ minWidth: "155px" }}>
          <InputLabel>Seřadit</InputLabel>
          <Select label="Seřadit" value="" onChange={() => {}}>
            <MenuItem key="time" value="time">
              Čas úkonu
            </MenuItem>
            <MenuItem key="warning" value="warning">
              K dořešení
            </MenuItem>
          </Select>
        </FormControl>
      </div>

      <div
        style={{
          display: "grid",
          gap: "0 1rem",
          gridAutoFlow: "row",
          gridTemplateRows: "1fr",
          gridTemplateColumns: "repeat(3, 1fr)",
          fontSize: "0.8rem",
        }}
      >
        <BookedClientHeader />
        <BookedClientHeader />
        <BookedClientHeader />
      </div>
      <div
        style={{
          display: "grid",
          gap: "0 1rem",
          gridAutoFlow: "column",
          gridTemplateRows: `repeat(${listLimit / 3}, 1fr)`,
          gridTemplateColumns: "repeat(3, 1fr)",
          fontSize: "0.8rem",
        }}
      >
        {bookedClients.slice(0, listLimit).map((appointment) => (
          <BookedClient
            appointment={appointment}
            key={appointment.id}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarOpen={setSnackbarOpen}
          />
        ))}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginTop: "2rem",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <FormControl sx={{ minWidth: "155px" }}>
          <Select
            value={listLimit}
            onChange={(e) => {
              setListLimit(e.target.value);
            }}
          >
            <MenuItem key="15" value="15">
              15
            </MenuItem>
            <MenuItem key="18" value="18">
              18
            </MenuItem>
            <MenuItem key="21" value="21">
              21
            </MenuItem>
            <MenuItem key="24" value="24">
              24
            </MenuItem>
          </Select>
        </FormControl>
        <div>
          Zobrazeno{" "}
          {listLimit < bookedClients.length ? listLimit : bookedClients.length}{" "}
          z {bookedClients.length}
        </div>
      </div>
    </>
  );
}

function CheckedInClientHeader() {
  return (
    <div
      style={{
        display: "flex",
        border: "1px solid black",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0.5rem 1rem",
        gap: "1rem",
        textAlign: "left",
      }}
    >
      <span style={{ flex: "0 0 30%", fontWeight: "bold" }}>Jméno klienta</span>
      <span style={{ flex: "1 0" }}>Start</span>
      <span style={{ flex: "3 0" }}>Jméno úkonu</span>
      <span style={{ flex: "3 0" }}>Příchod</span>
      <span style={{ flex: "3 0" }}>Čeká</span>
      <span style={{ flex: "3 0" }}>K řešení</span>
      <span style={{ flex: "2 0" }}>Zpět</span>
    </div>
  );
}

function CheckedInClientList({ appointments }) {
  const [checkedInClients, setCheckedInClients] = useState(appointments);
  useEffect(() => {
    setCheckedInClients(
      appointments
        .filter(
          (appointment) =>
            appointment.status_id === status.CONFIRMED ||
            appointment.status_id === status.WAITING,
        )
        .sort((a, b) => a.status_id - b.status_id),
    );
  }, [appointments]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>Čekající</h2>
        <FormControl sx={{ minWidth: "155px" }}>
          <InputLabel>Seřadit</InputLabel>
          <Select
            label="Seřadit"
            value=""
            onChange={(e) => {
              if (e.target.value === "warning") {
                setCheckedInClients(
                  checkedInClients.sort((a, b) => a.status_id - b.status_id),
                );
              } else {
                setCheckedInClients(
                  checkedInClients.sort(
                    (a, b) =>
                      new Date(a.start).getTime() - new Date(b.start).getTime(),
                  ),
                );
              }
            }}
          >
            <MenuItem key="warning" value="warning">
              K dořešení
            </MenuItem>
            <MenuItem key="time" value="time">
              Podle času naplánovaného úkonu
            </MenuItem>
          </Select>
        </FormControl>
      </div>

      <div style={{ columnCount: 2 }}>
        <div
          style={{
            display: "grid",
            gap: "0 1rem",
            gridAutoFlow: "row",
            gridTemplateRows: "1fr",
            gridTemplateColumns: "1fr",
            fontSize: "0.8rem",
          }}
        >
          <CheckedInClientHeader />
          <CheckedInClientHeader />
        </div>
      </div>

      <div style={{ columnCount: 2 }}>
        <div
          style={{
            display: "grid",
            gap: "0 1rem",
            gridAutoFlow: "row",
            gridTemplateRows: "1fr",
            gridTemplateColumns: "1fr",
            fontSize: "0.8rem",
          }}
        >
          {checkedInClients.map((appointment) => (
            <CheckedInClient key={appointment.id} appointment={appointment} />
          ))}
        </div>
      </div>
    </>
  );
}

function TreatedClientHeader() {
  return (
    <div
      style={{
        display: "flex",
        border: "1px solid black",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0.5rem 1rem",
        gap: "1rem",
        textAlign: "left",
      }}
    >
      <span style={{ fontWeight: "bold" }}>Jméno klienta</span>
      <span style={{}}>Objednat</span>
    </div>
  );
}

function TreatedClientList({ appointments }) {
  const [treatedClients, setTreatedClients] = useState(appointments);
  useEffect(() => {
    setTreatedClients(
      appointments
        .filter((appointment) => appointment.status_id === status.FINISHED)
        .sort(
          (a, b) =>
            new Date(
              a.audit.data.findLast(
                (change) =>
                  change?.changed_fields?.status_id === String(status.FINISHED),
              )?.created_at,
            ).getTime() -
            new Date(
              b.audit.data.findLast(
                (change) =>
                  change?.changed_fields?.status_id === String(status.FINISHED),
              )?.created_at,
            ).getTime(),
        ),
    );
  }, [appointments]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>Po úkonu</h2>
      </div>

      <div
        style={{
          fontSize: "0.8rem",
        }}
      >
        <TreatedClientHeader />
      </div>

      <div
        style={{
          display: "grid",
          gap: "0 1rem",
          gridAutoFlow: "row",
          gridTemplateRows: "repeat(5, 1fr)",
          gridTemplateColumns: "repeat(1, 1fr)",
          fontSize: "0.8rem",
        }}
      >
        {treatedClients.map((appointment) => (
          <TreatedClient key={appointment.id} appointment={appointment} />
        ))}
      </div>
    </>
  );
}

function searchAppointments(appointments, searchTerm, status) {
  const searchTerms = searchTerm.split(" ").map((term) =>
    term
      .toLowerCase()
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, ""),
  );

  let filteredAppointments = appointments;
  if (status || status === 0) {
    filteredAppointments = filteredAppointments.filter(
      (appointment) => appointment.status_id === status,
    );
  }
  searchTerms.forEach((term) => {
    filteredAppointments = filteredAppointments.filter(
      (appointment) =>
        appointment.client.first_name
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .includes(term) ||
        appointment.client.last_name
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .includes(term) ||
        appointment.client.birth_number?.includes(term) ||
        appointment.client.phone?.includes(term) ||
        appointment.start.split(" ")[1].includes(term) ||
        appointment.start.split(" ")[1].replace(":", "").includes(term),
    );
  });
  return filteredAppointments;
}

function appointmentDelayedBy(appointmentTime) {
  const now = new Date();
  const appointment = d.parseDateTime(appointmentTime);
  const diff = now - appointment;
  if (diff < 0) {
    return { hours: "00", minutes: "00" };
  }
  const hours = Math.floor(diff / 1000 / 60 / 60)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((diff / 1000 / 60) % 60)
    .toString()
    .padStart(2, "0");

  return { hours, minutes };
}

function ConfirmationModal({
  appointment,
  handleCloseConfirmationModal,
  openConfirmationModal,
  setSnackbarMessage,
  setSnackbarOpen,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleMoveAppointment = () => {
    handleCloseConfirmationModal();
    api
      .patchAppointment({
        id: appointment.id,
        status_id: status.CONFIRMED,
      })
      .then(() => {
        setSnackbarMessage(
          `Klient ${f.fullName(appointment.client)} byl přesunut do čekajících.`,
        );
        setSnackbarOpen(true);
      });
  };

  return (
    <Modal open={openConfirmationModal} onClose={handleCloseConfirmationModal}>
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Přesunutí do čekajících
          </Typography>
          <span
            style={{ cursor: "pointer" }}
            onClick={handleCloseConfirmationModal}
          >
            X
          </span>
        </div>
        <Typography id="modal-modal-description" sx={{ my: 2 }}>
          Opravdu chcete přesunout klienta{" "}
          <strong>{f.fullName(appointment.client)}</strong> do čekajících?
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button variant="contained" onClick={handleCloseConfirmationModal}>
            Zrušit
          </Button>
          <Button variant="contained" onClick={handleMoveAppointment}>
            Přesunout
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
