import { DOUBLE_CLICK_DELAY } from "../constants";
import * as u from "../utils/util";
import { status } from "shared/src/appointment.mjs";

/**
 * @typedef Appointment
 * @property {string} start
 * @property {string} end
 * @property {number} status_id
 * @property {string} calendar_id
 *
 * @typedef Event
 * @property {Date} start
 * @property {Date} end
 * @property {string} backgroundColor
 * @property {Appointment} extendedProps
 */

/**
 * @param {{status_id: number, service: {color: string}}} appointment
 *
 * @returns {string}
 */
export function appointmentBackground(appointment) {
  const bgColor = appointment.service?.color;

  if (appointment.status_id === status.WAITING) {
    const complementColor = u.complementColor(bgColor);
    return `repeating-linear-gradient(45deg, ${bgColor}, ${bgColor} 10px, ${complementColor} 10px, ${complementColor} 20px)`;
  }

  return bgColor;
}

/**
 * @param {Event} event
 *
 * @returns {string}
 */
export function eventStyle(event) {
  return `background: ${appointmentBackground(event.extendedProps)}`;
}

export function createClickHandler({ onClick, onDoubleClick }) {
  let timeoutId = null;
  const clear = () => {
    clearTimeout(timeoutId);
    timeoutId = null;
  };

  return function (e) {
    if (timeoutId == null) {
      timeoutId = setTimeout(() => {
        clear();
        onClick(e);
      }, DOUBLE_CLICK_DELAY);
      return;
    }

    clear();
    onDoubleClick(e);
  };
}
