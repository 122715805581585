import {
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useWizardContext } from "./AppointmentWizardContext";
import TasksSelectInput from "./TasksSelectInput.jsx";

const NotesForReceptionForm = ({ tasks, initialNotes }) => {
  const { t } = useTranslation();
  const { setNurseNotes } = useWizardContext();
  const [formData, setFormData] = useState({
    task: "",
    nextCheck: "",
    quickInfo: initialNotes || "",
    clientSelfBook: false,
  });

  useEffect(() => {
    if (initialNotes) {
      setFormData((prevData) => ({
        ...prevData,
        quickInfo: initialNotes,
      }));
    }
  }, [initialNotes]);

  const handleChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const newFormData = { ...formData, [e.target.name]: value };
    setFormData(newFormData);

    if (e.target.name === "quickInfo") {
      setNurseNotes(value);
    }
  };

  return (
    <>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        {t("appointmentNotes.notesForReception")}
      </Typography>

      <Grid container spacing={2}>
        <Grid size={3}>
          <TasksSelectInput
            tasks={tasks}
            value={formData.task}
            labelKey="appointmentNotes.task"
            onChange={handleChange}
          />
        </Grid>
        <Grid size={3}>
          <TextField
            sx={{ my: 1, width: "100%" }}
            name="nextCheck"
            id="nextCheck"
            required
            label={t("appointmentNotes.nextCheck")}
            onChange={handleChange}
            value={formData.nextCheck}
          />
        </Grid>
        <Grid size={3}>
          <TextField
            sx={{ my: 1, width: "100%" }}
            name="quickInfo"
            id="quickInfo"
            label={t("appointmentNotes.quickInfo")}
            onChange={handleChange}
            value={formData.quickInfo}
            multiline
            rows={4}
          />
        </Grid>
        <Grid size={3}>
          <FormControlLabel
            sx={{ my: 1.5, width: "100%" }}
            control={
              <Checkbox
                name="clientSelfBook"
                onChange={handleChange}
                checked={formData.clientSelfBook}
              />
            }
            label={t("appointmentNotes.clientSelfBook")}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default NotesForReceptionForm;
