import "./logging";
import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./app";
import "../resources/styles.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./config/i18n";
import { AuthProvider } from "./context/authProvider";
import { StayLoggedInProvider } from "./context/stayLoggedInContext";
import { NotificationsProvider } from "./context/NotificationContext";
import { LicenseInfo } from "@mui/x-license";
import { AuthenticationTokenProvider } from "./context/AuthenticationTokenContext";

function init() {
  LicenseInfo.setLicenseKey(
    "7fc276212e4a549143ad2e7591577f0eTz0xMDEwMzUsRT0xNzYxOTk3MzI0MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=",
  );
  const el = document.getElementById("app");
  const root = createRoot(el);

  root.render(
    <StayLoggedInProvider>
      <I18nextProvider i18n={i18n}>
        <AuthProvider>
          <AuthenticationTokenProvider>
            <NotificationsProvider>
              <App />
            </NotificationsProvider>
          </AuthenticationTokenProvider>
        </AuthProvider>
      </I18nextProvider>
    </StayLoggedInProvider>,
  );
}

init();
