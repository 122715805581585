import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Paper,
  Stack,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import TranslationSwitcher from "../components/TranslationSwitcher";
import { useStayLoggedIn } from "../context/stayLoggedInContext";
import { handleLoginWithAzure } from "./../auth";
import { clearIntendedUrl, getIntendedUrl } from "../utils/util";
import { useAuthenticationToken } from "../context/AuthenticationTokenContext";
import { useNavigate } from "../url";

const LoginButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  color: "black",
  "&:hover": {
    backgroundColor: "#85c8ff",
  },
  marginBottom: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(1),
}));

const MicrosoftIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="24px"
    height="24px"
    {...props}
  >
    <path fill="#f1511b" d="M22 22H3V3h19v19z" />
    <path fill="#80cc28" d="M45 22H26V3h19v19z" />
    <path fill="#00a1f1" d="M22 45H3V26h19v19z" />
    <path fill="#fbbc09" d="M45 45H26V26h19v19z" />
  </svg>
);

const LoginPage = () => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const { stayLoggedIn, setStayLoggedIn } = useStayLoggedIn();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [_, setAuthToken] = useAuthenticationToken();
  const [authToken] = useAuthenticationToken();

  useEffect(() => {
    const intendedUrl = getIntendedUrl();
    if (authToken != null && intendedUrl) {
      navigate(intendedUrl, { replace: true });
      clearIntendedUrl();
    }
  }, [navigate, authToken]);

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const response = await instance.loginPopup({
        scopes: [
          "https://labs1504tmtest.onmicrosoft.com/d84765fc-e9f7-4aa7-abc2-1969900ac5ad/read",
        ],
      });
      const azureToken = response.accessToken;

      const token = await handleLoginWithAzure(azureToken, stayLoggedIn);
      setAuthToken(token);

      const intendedUrl = getIntendedUrl();
      if (intendedUrl) {
        navigate(intendedUrl, { replace: true });
        clearIntendedUrl();
      } else {
        navigate("/", { replace: true });
      }
    } catch (error) {
      console.error("LoginPage - Login failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      bgcolor="#ececec"
    >
      <Paper
        elevation={3}
        sx={{ padding: 4, borderRadius: 2, textAlign: "center" }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          {t("login.title")}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t("login.description")}
        </Typography>
        <Stack>
          <LoginButton
            variant="contained"
            onClick={handleLogin}
            disabled={isLoading}
          >
            <MicrosoftIcon style={{ marginRight: 8 }} />
            {t("login.loginButton")}
          </LoginButton>
          <FormControlLabel
            control={
              <Checkbox
                checked={stayLoggedIn}
                onChange={(e) => setStayLoggedIn(e.target.checked)}
                disabled={isLoading}
              />
            }
            label={t("login.stayLoggedIn")}
          />
        </Stack>
      </Paper>
      <Stack mt={4}>
        <TranslationSwitcher />
      </Stack>
    </Box>
  );
};

export default LoginPage;
