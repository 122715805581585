import "@event-calendar/core/index.css";
import React from "react";
import { renderToStaticMarkup } from "react-dom/server.browser";
import { useLoaderData } from "react-router-dom";
import ECal from "@event-calendar/core";
import ResourceTimeGridPlugin from "@event-calendar/resource-time-grid";
import InteractionPlugin from "@event-calendar/interaction";
import * as api from "../services/api";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  DialogActions,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress,
  Typography,
  useTheme,
  ListItemText,
  Checkbox,
  IconButton,
} from "@mui/material";
import * as f from "../utils/formatter";
import * as ws from "../utils/ws";
import { useTranslation } from "react-i18next";
import ButtonLoader from "../components/ButtonLoader";
import * as d from "shared/src/date.mjs";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import { groupBy, indexBy, throttle } from "shared/src/util.mjs";
import AutocompleteSelect from "../components/AutocompleteSelect";
import { status } from "shared/src/appointment.mjs";
import { THROTTLE_REFRESH } from "../constants";
import { errorsFromApi, FormErrorList } from "../form";
import {
  appointmentBackground,
  createClickHandler,
  eventStyle,
} from "../components/calendar";
import { animationInterval } from "../utils/util";
import { useLatest } from "../hooks";
import { createUrlString } from "../url";
import Link from "../components/Link";
import {
  currentLocationFromRequest,
  useCurrentLocation,
} from "../components/LocationSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlarmExclamation,
  faHourglass,
} from "@fortawesome/pro-solid-svg-icons";

/**
 * @typedef EventCalendar
 * @property {() => Event[]} getEvents
 * @property {(e: Event) => EventCalendar} updateEvent
 *
 * @typedef Service
 * @property {string} name
 *
 * @typedef Client
 * @property {string} first_name
 * @property {string} last_name
 *
 * @typedef Audit
 * @property {created_at} string
 * @property {object} changed_fields
 *
 * @typedef AuditConnection
 * @property {Audit[]} data
 *
 * @typedef Appointment
 * @property {string} start
 * @property {string} end
 * @property {number} status_id
 * @property {string} calendar_id
 * @property {Service} service
 * @property {Client} client
 * @property {AuditConnection} audit
 *
 * @typedef Calendar
 * @property {string} id
 * @property {string} title
 * @property {string} location_id
 *
 * @typedef Resource
 * @property {Calendar} extendedProps
 *
 * @typedef Event
 * @property {Date} start
 * @property {Date} end
 * @property {'auto' | 'background'} display
 * @property {Appointment} extendedProps
 *
 * @typedef ResourceStatusEmpty
 * @property {'empty'} type
 *
 * @typedef ResourceStatusWaiting
 * @property {'waiting'} type
 * @property {Appointment} appointment
 *
 * @typedef ResourceStatusTreating
 * @property {'treating'} type
 * @property {Appointment} appointment
 * @property {number} remaining
 *
 * @typedef {ResourceStatusEmpty | ResourceStatusWaiting | ResourceStatusTreating} ResourceStatus
 *
 * @typedef AssignCalendar
 * @property {string} appointment_id
 * @property {Calendar} actual_calendar
 */

export async function loader({ request }) {
  const location_id = currentLocationFromRequest(request);

  return api.loadCalendarData({
    calendarFilter: { visible: { eq: true }, location_id: { eq: location_id } },
  });
}

/**
 * @param {Appointment} event
 *
 * @returns {Date | null}
 */
function getStatusChangedAt(event) {
  const last = event.audit.data.findLast(
    (a) => a.changed_fields?.status_id != null,
  );

  return d.parseUtcDateTime(last?.created_at);
}

const interestingResourceStatus = new Set([status.WAITING, status.TREATING]);

/**
 * @param {Event[]} events
 * @param {Date} now
 *
 * @returns {ResourceStatus}
 */
export function resourceStatus(events, now) {
  const event = events.find((ev) =>
    interestingResourceStatus.has(ev.extendedProps.status_id),
  );

  if (event == null) {
    return {
      type: "empty",
    };
  }

  const appointment = event.extendedProps;
  switch (appointment.status_id) {
    case status.WAITING: {
      return {
        type: "waiting",
        appointment,
      };
    }
    case status.TREATING: {
      const changedAt = getStatusChangedAt(appointment);
      const duration = (appointment.service?.duration ?? 0) * 60 * 1000;
      const remaining =
        changedAt == null ? 0 : changedAt.getTime() + duration - now;

      return {
        type: "treating",
        remaining,
        appointment,
      };
    }
  }
}

function msToM(ms) {
  return Math.round(ms / 1000 / 60);
}

/**
 * @param {Date} start
 * @param {Date} end
 */
function duration(start, end) {
  return msToM(end - start);
}

const waitingIcon = renderToStaticMarkup(
  <FontAwesomeIcon icon={faHourglass} />,
);

const waitingSinceStartIcon = renderToStaticMarkup(
  <FontAwesomeIcon icon={faAlarmExclamation} />,
);

/**
 * @param {Event} event
 * @param {Date} now
 */
function waitingTimes(event, now) {
  const appointment = event.extendedProps;
  if (appointment.status_id === status.WAITING) {
    const changedAt = getStatusChangedAt(appointment);

    return {
      waiting_time: waitingIcon + ` ${msToM(now - changedAt)}m`,
      waiting_time_since_start:
        waitingSinceStartIcon + ` ${msToM(now - event.start)}m`,
      late_by: `Opozdil se o: ${msToM(changedAt - event.start)}m`,
    };
  }
}

/**
 * @param {Set<string>} selectedVisibleInfo
 * @param {Date} now
 */
function createEventContentFn(selectedVisibleInfo, now) {
  /**
   * @param {object} info
   * @param {Event} info.event
   */
  return function (info) {
    const event = info.event;
    const appointment = event.extendedProps;
    const times = waitingTimes(event, now);

    const shownData = [
      selectedVisibleInfo.has("appointment.time") &&
        `${f.time24(appointment.start)}-${f.time24(appointment.end)} (${f.minutesToDuration(duration(event.start, event.end))})`,
      f.fullName(appointment.client),
      selectedVisibleInfo.has("appointment.service") &&
        appointment.service?.name,
      appointment.description,
      selectedVisibleInfo.has("appointment.waiting_time") &&
        times?.waiting_time,
      selectedVisibleInfo.has("appointment.waiting_time_since_start") &&
        times?.waiting_time_since_start,
      selectedVisibleInfo.has("appointment.late_by") && times?.late_by,
    ].filter((v) => v !== false && v != null);

    return {
      html: `<div class="calendar-event" style="${eventStyle(info.event)}">
        ${shownData.join("<br>")}
      </div>`,
    };
  };
}

/**
 * @param {object} opts
 * @param {HTMLElement} opts.el
 * @param {React.MutableRefObject<string | undefined>} opts.location_idRef
 * @param {React.MutableRefObject<number>} opts.selectedTime_ref
 * @param {(event: object | null) => void} opts.setEditEvent
 * @param {(Appointment[]) => void} opts.setAdhoc
 */
function createEcal({
  el,
  setEditEvent,
  location_idRef,
  selectedTime_ref,
  setAdhoc,
}) {
  const clickHandler = createClickHandler({
    onClick: (info) => {
      setEditEvent(info.event.extendedProps);
    },
    onDoubleClick: (info) => {
      const clientId = info.event.extendedProps?.client?.id;
      if (clientId == null) {
        return;
      }

      window.open(
        createUrlString(
          location.origin,
          location.search,
          `/clients/${clientId}`,
        ),
        "_blank",
      );
    },
  });

  /** @type {EventCalendar} */
  const cal = new ECal({
    target: el,
    props: {
      plugins: [ResourceTimeGridPlugin, InteractionPlugin],
      options: {
        view: "resourceTimeGridDay",
        nowIndicator: true,
        headerToolbar: { start: "", center: "", end: "" },
        slotDuration: "00:10",
        slotLabelFormat: { timeStyle: "short", hour12: false },
        allDaySlot: false,
        slotMinTime: "24:00",
        slotMaxTime: "00:00",
        flexibleSlotTimeLimits: true,
        scrollTime: "09:00",
        eventTextColor: "#000",
        eventDurationEditable: false,
        eventStartEditable: false,
        eventDrop: async (info) => {
          const event = info.event;
          const newData = {
            id: event.id,
            start: d.toString(event.start),
            end: d.toString(event.end),
            ...(info.newResource == null
              ? {}
              : { calendar_id: info.newResource.id }),
          };

          const result = await api.patchAppointment(newData);
          if (result.errors != null) {
            info.revert();
          }
        },
        eventClassNames: (info) => {
          const appointment = info.event.extendedProps;

          return `event-id-${info.event.id} status-${appointment.status_id}`;
        },
        eventClick: (info) => {
          clickHandler(info);
        },
        eventSources: [
          {
            events: async function (fetchInfo) {
              const location_id = location_idRef.current;
              const selectedTime = selectedTime_ref.current ?? 0;
              if (location_id == null) {
                return [];
              }

              const start = fetchInfo.start;
              const end =
                selectedTime === 0
                  ? fetchInfo.end
                  : (() => {
                      const end = new Date();
                      end.setHours(end.getHours() + selectedTime);

                      return new Date(
                        Math.min(fetchInfo.end.getTime(), end.getTime()),
                      );
                    })();

              const resp = await api.loadFlooreManagerAppointments({
                filter: {
                  start: {
                    gte: d.toString(start),
                    lt: d.toString(end),
                  },
                  location_id: { eq: location_id },
                  status_id: {
                    in: [
                      status.BOOKED,
                      status.CONFIRMED,
                      status.WAITING,
                      status.TREATING,
                    ],
                  },
                },
                auditFilter: {
                  changed_fields: {
                    name: "status_id",
                    expr: { null: false },
                  },
                },
              });

              const appointments = resp.data.appointments.data;
              const appointmentById = indexBy(appointments, (a) => a.id);

              const adhoc = appointments.filter((a) => a.calendar_id == null);
              setAdhoc(adhoc);

              requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                  Array.from(
                    document.querySelectorAll("article.ec-event"),
                  ).forEach((el) => {
                    const eventId = Array.from(el.classList)
                      .find((c) => c.startsWith("event-id-"))
                      .replace("event-id-", "");

                    const draggable =
                      status.WAITING === appointmentById[eventId]?.status_id &&
                      appointmentById[eventId]?.actual_calendar_id == null;

                    if (draggable) {
                      el.setAttribute("draggable", true);
                      el.ondragstart = function (e) {
                        const el = e.currentTarget;
                        e.dataTransfer.setDragImage(el, 0, 0);
                        el.classList.add("dragging");

                        e.dataTransfer.dropEffect = "move";
                        e.dataTransfer.setData(
                          "app/json",
                          JSON.stringify({ appointment_id: eventId }),
                        );
                      };
                      el.ondragend = function (e) {
                        const el = e.currentTarget;
                        el.classList.remove("dragging");
                      };
                    }
                  });
                });
              });

              return appointments.map((e) => {
                const event = {
                  ...e,
                  title: `${f.fullName(e.client)}
${e.service?.name}
${e.description ?? ""}`,
                  resourceId: e.calendar_id,
                  backgroundColor: e.service?.color,
                  start: new Date(e.start),
                  end: new Date(e.end),
                  extendedProps: e,
                };

                return event;
              });
            },
          },
        ],
      },
    },
  });

  return cal;
}

function ExtraInfo({ label, value }) {
  return (
    <div className="calendar-extra-info-container">
      <strong>{label}:&nbsp;</strong>
      <div className="calendar-extra-info--value">{value}</div>
    </div>
  );
}

function isCalendarAvailable(el) {
  return (
    (el.querySelector(".extra-info--service")?.textContent?.length ?? 0) === 0
  );
}

function isValidDraggable(e) {
  return e.dataTransfer.types.includes("app/json");
}

/**
 * @param {number} [remaining]
 * @param {import('@mui/material').Theme} theme
 */
function remaining(remaining, theme) {
  const color =
    (remaining ?? 0) < 0
      ? theme.palette.error.main
      : theme.palette.text.primary;

  return {
    status: {
      props: {
        style: {
          color: color,
        },
      },
    },
    label: `${remaining ? f.msToTime(remaining) : "00:00"}`,
  };
}

/**
 * @param {ResourceStatus} status
 * @param {import('@mui/material').Theme} theme
 */
function resourceStatusInfo(status, theme, setEditEvent) {
  switch (status.type) {
    case "empty":
      return {
        slot: {
          props: {
            style: {
              backgroundColor: theme.palette.background.default,
              color: theme.palette.text.primary,
              cursor: "default",
            },
            onClick: null,
          },
        },
        remaining: remaining(null, theme),
        extraInfo: {
          service: "",
          client: "",
          nurse: "",
        },
      };
    case "waiting": {
      const appointment = status.appointment;
      const client = appointment.client;
      const employee = appointment.employee;

      return {
        slot: {
          props: {
            style: {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              cursor: "pointer",
            },
            onClick: () => setEditEvent(appointment),
          },
        },
        remaining: remaining(null, theme),
        extraInfo: {
          service: appointment.service?.name,
          client: `${f.fullName(client)}`,
          nurse: `${f.fullName(employee)}`,
        },
      };
    }
    case "treating": {
      const appointment = status.appointment;
      const client = appointment.client;
      const employee = appointment.employee;

      return {
        slot: {
          props: {
            style: {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              cursor: "pointer",
            },
            onClick: () => setEditEvent(appointment),
          },
        },
        remaining: remaining(status.remaining, theme),
        extraInfo: {
          service: appointment.service?.name,
          client: `${f.fullName(client)}`,
          nurse: `${f.fullName(employee)}`,
        },
      };
    }
  }
}

function Resource({ resource, setAssignCalendar, now, events, setEditEvent }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const status = resourceStatus(events, now);
  const info = resourceStatusInfo(status, theme, setEditEvent);

  return (
    <Box
      className="floor-manager-resource"
      flexGrow="1"
      flexShrink="1"
      width={0}
      onDragEnter={function (e) {
        const el = e.currentTarget;
        if (isValidDraggable(e) && isCalendarAvailable(el)) {
          el.classList.add("dropping");
        }
      }}
      onDragLeave={function (e) {
        if (e.target !== e.currentTarget) {
          return;
        }
        const el = e.currentTarget;
        el.classList.remove("dropping");
      }}
      onDragOver={function (e) {
        const el = e.currentTarget;
        if (isValidDraggable(e) && isCalendarAvailable(el)) {
          e.preventDefault();
        }
      }}
      onDrop={function (e) {
        const el = e.currentTarget;
        el.classList.remove("dropping");

        const { appointment_id } = JSON.parse(
          e.dataTransfer.getData("app/json"),
        );

        setAssignCalendar({
          appointment_id: appointment_id,
          actual_calendar: resource,
        });
      }}
    >
      <div style={{ textAlign: "center" }}>{resource.title}</div>
      <div className="calendar-slot calendar-slot-1" {...info.slot.props}>
        <div className="calendar-status" {...info.remaining.status.props}>
          <div className="calendar-status--waiting-icon">
            <AvTimerIcon />
          </div>
          <div className="calendar-status--label">{info.remaining.label}</div>
        </div>
        <div className="calendar-extra-info">
          <ExtraInfo
            label={t("fcalendar.client")}
            value={info.extraInfo.client}
          />
          <ExtraInfo
            label={t("fcalendar.service")}
            value={info.extraInfo.service}
          />
          <ExtraInfo
            label={t("fcalendar.nurse")}
            value={info.extraInfo.nurse}
          />
        </div>
      </div>
      <div className="calendar-slot calendar-slot-2">
        <div className="calendar-extra-info">
          <ExtraInfo label={t("fcalendar.client")} />
          <ExtraInfo label={t("fcalendar.service")} />
          <ExtraInfo label={t("fcalendar.nurse")} />
        </div>
      </div>
    </Box>
  );
}

/**
 * @param {React.MutableRefObject<EventCalendar | null>} calRef
 */
function ResourceList({ calRef, resources, setAssignCalendar, setEditEvent }) {
  const theme = useTheme();
  const [now, setNow] = React.useState(new Date());
  React.useEffect(() => {
    const controller = new AbortController();
    animationInterval(1000, controller.signal, () => {
      setNow(new Date());
    });

    return function () {
      controller.abort();
    };
  });

  const eventsByCalendarId = groupBy(
    calRef.current?.getEvents() ?? [],
    (ec) => ec.extendedProps.actual_calendar_id,
  );

  return (
    <Box
      className="floor-manager-calendar"
      display="flex"
      position="sticky"
      top="var(--app-sticky-top)"
      backgroundColor={theme.palette.background.default}
      zIndex="1010"
    >
      <div className="ec-time-grid" style={{ display: "flex" }}>
        <div className="ec-sidebar">
          <div className="ec-sidebar-title">all-day</div>
        </div>
        <div className="ec-lines"></div>
      </div>
      <Box display="flex" flexGrow="1" flexShrink="1">
        {resources.length === 0 ? (
          <Resource
            resource={{ title: <>&nbsp;</> }}
            setAssignCalendar={() => {}}
            setEditEvent={() => {}}
            events={[]}
            now={now}
          />
        ) : (
          resources.map((r) => {
            return (
              <Resource
                key={r.id}
                resource={r}
                setAssignCalendar={setAssignCalendar}
                setEditEvent={setEditEvent}
                events={eventsByCalendarId[r.id] ?? []}
                now={now}
              />
            );
          })
        )}
      </Box>
    </Box>
  );
}

const Cal = React.memo(function Cal({
  date,
  setEditEvent,
  cellHeight,
  location_id,
  selectedTime,
  setAdhoc,
  resources,
  cal,
  selectedVisibleInfo,
}) {
  const ecalEl = React.useRef();
  const location_idRef = React.useRef();
  const selectedTime_ref = React.useRef(selectedTime);

  React.useEffect(() => {
    const refresh = throttle(() => {
      cal.current?.refetchEvents();
    }, THROTTLE_REFRESH);

    function maybeRefresh(msg) {
      if (msg.type === "appointment_changed") {
        refresh();
      }
    }

    ws.subscribe(maybeRefresh);

    return () => {
      ws.unsubscribe(maybeRefresh);
    };
  }, []);

  React.useEffect(() => {
    location_idRef.current = location_id;
    selectedTime_ref.current = selectedTime;
    cal.current?.refetchEvents();
  }, [location_id, selectedTime]);

  React.useEffect(() => {
    cal.current = createEcal({
      el: ecalEl.current,
      setEditEvent,
      location_idRef,
      selectedTime_ref,
      setAdhoc,
    });

    return function () {
      cal.current.destroy();
      cal.current = null;
    };
  }, []);

  React.useEffect(() => {
    const c = cal.current;
    if (c == null) {
      return;
    }

    const infoSet = new Set(selectedVisibleInfo);
    const update = () => {
      c.setOption("eventContent", createEventContentFn(infoSet, new Date()));
    };

    const controller = new AbortController();
    update();
    animationInterval(1000, controller.signal, () => {
      update();
    });

    return function () {
      controller.abort();
    };
  }, [selectedVisibleInfo]);

  React.useEffect(() => {
    const c = cal.current;
    if (c == null) {
      return;
    }

    c.setOption("slotHeight", cellHeight);
    document.body.style.setProperty("--app-ec-slot-height", `${cellHeight}px`);
  }, [cellHeight]);

  React.useEffect(() => {
    if (cal.current == null) {
      return;
    }

    cal.current.setOption("resources", resources);
  }, [resources]);

  React.useEffect(() => {
    const c = cal.current;
    if (c == null) {
      return;
    }

    c.setOption("date", date);
  }, [date]);

  return (
    <>
      <div id="ecal" className="floor-manager-calendar" ref={ecalEl} />
    </>
  );
});

/**
 * @param {object} props
 * @param {Appointment} props.editEvent
 * @param {(event?: Appointment) => void} props.setEditEvent
 * @param {string} props.location_id
 */
function EventDialog({
  setEditEvent,
  editEvent,
  appointmentStatuses,
  location_id,
}) {
  const { t } = useTranslation();
  const client = editEvent.client;
  const [loading, setLoading] = React.useState(false);
  const serviceFilter = React.useMemo(() => {
    return { location_id: { eq: location_id } };
  }, [location_id]);
  const so = useServiceOptions(serviceFilter);

  return (
    <Dialog open={true} onClose={() => setEditEvent(null)}>
      <DialogTitle>{t("calendar.clientReservation")}</DialogTitle>
      <DialogContent>
        {client && (
          <>
            <Link to={`/clients/${client.id}`} target="_blank">
              {f.fullName(client)}
            </Link>
            <br />
          </>
        )}
        <Box display="flex" flexDirection="column" gap="1rem">
          <Select
            value={editEvent.status_id}
            onChange={async (e) => {
              setEditEvent(null);
              await api.patchAppointment({
                id: editEvent.id,
                status_id: e.target.value,
              });
            }}
            sx={{ width: "100%" }}
            disabled={loading}
          >
            {appointmentStatuses.map((s) => (
              <MenuItem key={s.id} value={s.id}>
                {s.name}
              </MenuItem>
            ))}
          </Select>
          <AutocompleteSelect
            required
            value={editEvent.service}
            loading={so.loading}
            options={so.options}
            disabled={loading}
            onChange={async (value) => {
              const ev = editEvent;
              if (value == null || value.id === ev.service.id) {
                return;
              }

              const fromDate = new Date(ev.start);
              const toDate = new Date(fromDate.getTime());
              toDate.setMinutes(toDate.getMinutes() + value.duration);

              setEditEvent(null);
              await api.patchAppointment({
                id: editEvent.id,
                service_id: value.id,
                start: d.toString(fromDate),
                end: d.toString(toDate),
              });
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {editEvent.actual_calendar_id !== null && (
          <Button
            disabled={loading}
            onClick={async () => {
              setEditEvent(null);
              await api.patchAppointment({
                id: editEvent.id,
                status_id: status.WAITING,
                actual_calendar_id: null,
              });
            }}
          >
            {t("fcalendar.backToWaiting")}
          </Button>
        )}
        <Button
          color="error"
          disabled={loading}
          onClick={async () => {
            setLoading(true);
            await api.deleteAppointment({ id: editEvent.id });
            setEditEvent(null);
            setLoading(false);
          }}
        >
          {loading && <ButtonLoader />}
          {t("buttons.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

async function fetchNurses(signal) {
  const res = await api.availableNurses({ signal });

  return res.data.employees.data;
}

function useNurseOptions() {
  const [options, setOptions] = React.useState();
  useLatest(async (signal) => {
    setOptions(null);
    setOptions(await fetchNurses(signal));
  }, []);

  return { options: options ?? [], loading: options == null };
}

function initialAssignCalendarDialogState() {
  return {
    loading: false,
    errors: [],
  };
}

/**
 * @param {object} props
 * @param {(data?: AssignCalendar) => void} props.setAssignCalendar
 * @param {AssignCalendar} props.assignCalendar
 */
function AssignCalendarDialog({ setAssignCalendar, assignCalendar }) {
  const [state, setState] = React.useState(initialAssignCalendarDialogState());
  const { t } = useTranslation();
  const { options, loading } = useNurseOptions();

  return (
    <Dialog open={true} onClose={() => setAssignCalendar(null)}>
      <DialogTitle>
        {state.loading && (
          <CircularProgress
            size={24}
            sx={{ position: "absolute", left: "calc(50% - 24px)" }}
          />
        )}
        Přesunutí na {assignCalendar.actual_calendar.title}
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <FormControl required disabled={state.loading}>
            <RadioGroup
              onChange={async (_, value) => {
                setState((state) => ({ ...state, loading: true }));
                const result = await api.patchAppointment({
                  id: assignCalendar.appointment_id,
                  actual_calendar_id: assignCalendar.actual_calendar.id,
                  employee_id: value,
                });
                const errors = errorsFromApi({ result, t });
                setState((state) => ({ ...state, errors: errors }));
                if (errors.length === 0) {
                  setAssignCalendar(null);
                }
                setState((state) => ({ ...state, loading: false }));
              }}
            >
              {options.map((o) => {
                return (
                  <FormControlLabel
                    key={o.id}
                    value={o.id}
                    control={<Radio />}
                    label={f.fullName(o)}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        )}
        <FormErrorList formErrors={state.errors} />
      </DialogContent>
    </Dialog>
  );
}

async function fetchServiceOptions(signal, filter) {
  if (filter == null) {
    return [];
  }

  const res = await api.serviceOptions(
    {
      filter: filter,
    },
    { signal },
  );

  return res.data.services.data;
}

function useServiceOptions(filter) {
  const [options, setOptions] = React.useState();
  useLatest(
    async (signal) => {
      setOptions(null);
      setOptions(await fetchServiceOptions(signal, filter));
    },
    [filter],
  );

  return { options: options ?? [], loading: options == null };
}

const hourRange = [2, 3, 4];

const selectedVisibleInfoOptions = {
  "appointment.waiting_time": "Čas v čekárně",
  "appointment.waiting_time_since_start": "Čas od termínu",
  "appointment.late_by": "Zpoždění",
  "appointment.time": "Čas schůzky",
  "appointment.service": "Typ schůzky",
};

const Toolbar = React.memo(function Toolbar({
  setState,
  selectedTime,
  selectedVisibleInfo,
  cellHeight,
}) {
  const { t } = useTranslation();
  const setSelectedTime = (selectedTime) =>
    setState((state) => ({ ...state, selectedTime }));
  const setSelectedVisibleInfo = (selectedVisibleInfo) =>
    setState((state) => ({ ...state, selectedVisibleInfo }));
  const allChecked =
    selectedVisibleInfo.length ===
    Object.keys(selectedVisibleInfoOptions).length;
  const setCellHeight = (cellHeight) =>
    setState((state) => ({ ...state, cellHeight }));

  return (
    <Box display="flex" gap={2} marginBottom={2} justifyContent="flex-end">
      <Typography variant="h1" width="100%">
        {t("fcalendar.planned")}
      </Typography>
      {cellHeight === 24 ? (
        <IconButton onClick={() => setCellHeight(48)}>&#9047;</IconButton>
      ) : (
        <IconButton onClick={() => setCellHeight(24)}>&#9040;</IconButton>
      )}
      <FormControl sx={{ minWidth: "155px" }}>
        <InputLabel>{t("fcalendar.selectTime")}</InputLabel>
        <Select
          label={t("fcalendar.selectTime")}
          value={selectedTime}
          onChange={(e) => {
            const selectedTime = e.target.value;
            setSelectedTime(selectedTime);
          }}
        >
          {hourRange.map((h) => (
            <MenuItem key={h} value={h}>
              {t("fcalendar.hour_range", { count: h })}
            </MenuItem>
          ))}
          <MenuItem value={0}>{t("fcalendar.hour_rangeAll")}</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ minWidth: "155px", maxWidth: "155px" }}>
        <InputLabel>Ukázat pouze</InputLabel>
        <Select
          label="Ukázat pouze"
          value={selectedVisibleInfo}
          multiple
          onChange={(e) => {
            const value = e.target.value;
            if (value.includes("all")) {
              if (allChecked) {
                setSelectedVisibleInfo([]);
                return;
              }

              setSelectedVisibleInfo(Object.keys(selectedVisibleInfoOptions));
              return;
            }

            setSelectedVisibleInfo(e.target.value);
          }}
          renderValue={(selected) =>
            selected.map((id) => selectedVisibleInfoOptions[id]).join(",")
          }
        >
          <MenuItem value="all">
            <Checkbox checked={allChecked} />
            <ListItemText primary="Vše" />
          </MenuItem>
          {Object.entries(selectedVisibleInfoOptions).map(([id, name]) => (
            <MenuItem key={id} value={id}>
              <Checkbox checked={selectedVisibleInfo.includes(id)} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
});

/**
 * @param {object} props
 * @param {Appointment[]} props.adhoc
 * @param {string} props.location_id
 */
function AdhocAppointments({ adhoc, location_id, setEditEvent }) {
  const { t } = useTranslation();
  const appointments = adhoc.filter((a) => a.location_id === location_id);
  const [now, setNow] = React.useState(new Date());
  React.useEffect(() => {
    const controller = new AbortController();
    animationInterval(1000, controller.signal, () => {
      setNow(new Date());
    });

    return function () {
      controller.abort();
    };
  });

  return (
    <Box display="flex" gap="1rem">
      {appointments.map((appointment) => {
        const draggable =
          appointment.status_id === status.WAITING &&
          appointment.actual_calendar_id == null;
        const client = appointment.client;
        const service = appointment.service;
        const clickHandler = createClickHandler({
          onClick: () => {
            setEditEvent(appointment);
          },
          onDoubleClick: () => {
            const clientId = appointment.client?.id;
            if (clientId == null) {
              return;
            }

            window.open(
              createUrlString(
                location.origin,
                location.search,
                `/clients/${clientId}`,
              ),
              "_blank",
            );
          },
        });

        const times = waitingTimes(
          {
            extendedProps: appointment,
            start: d.parseDateTime(appointment.start),
            end: d.parseDateTime(appointment.end),
          },
          now,
        );

        return (
          <Box
            key={appointment.id}
            border="1px solid black"
            padding={1}
            data-id={appointment.id}
            className={`calendar-adhoc-appointment status-${appointment.status_id}`}
            sx={{
              cursor: "pointer",
              background: appointmentBackground(appointment),
            }}
            onClick={clickHandler}
            draggable={draggable}
            onDragStart={(e) => {
              const el = e.currentTarget;
              e.dataTransfer.setDragImage(el, 0, 0);
              el.classList.add("dragging");
              e.dataTransfer.dropEffect = "move";
              e.dataTransfer.setData(
                "app/json",
                JSON.stringify({ appointment_id: appointment.id }),
              );
            }}
            onDragEnd={(e) => {
              const el = e.currentTarget;
              el.classList.remove("dragging");
            }}
          >
            {client && (
              <>
                {f.fullName(client)}
                <br />
              </>
            )}
            <strong>{t("fcalendar.service")}:</strong>&nbsp;
            {service?.name}
            <br />
            {times?.waiting_time}
          </Box>
        );
      })}
    </Box>
  );
}

function initialCalendarState() {
  return {
    date: f.dateInput(new Date()),
    editEvent: null,
    assignCalendar: null,
    cellHeight: 24,
    selectedTime: 0,
    selectedVisibleInfo: Object.keys(selectedVisibleInfoOptions),
    adhoc: [],
  };
}

export default function Calendar() {
  const { t } = useTranslation();
  const loaderData = useLoaderData();
  const appointmentStatuses = loaderData.data.appointmentStatuses.data;
  const resources = loaderData.data.calendars.data;
  const [state, setState] = React.useState(initialCalendarState);
  const cal = React.useRef();
  const location_id = useCurrentLocation();

  const { date, editEvent, cellHeight, assignCalendar } = state;
  const setEditEvent = React.useCallback(
    (editEvent) => setState((state) => ({ ...state, editEvent })),
    [setState],
  );
  const setAssignCalendar = React.useCallback(
    (assignCalendar) => setState((state) => ({ ...state, assignCalendar })),
    [setState],
  );
  const setAdhoc = React.useCallback(
    (adhoc) => setState((state) => ({ ...state, adhoc })),
    [setState],
  );

  return (
    <>
      <Typography variant="h1">{t("fcalendar.resources")}</Typography>
      <ResourceList
        calRef={cal}
        resources={resources}
        setAssignCalendar={setAssignCalendar}
        setEditEvent={setEditEvent}
      />
      <Typography variant="h1">{t("fcalendar.adhoc")}</Typography>
      <AdhocAppointments
        adhoc={state.adhoc}
        location_id={location_id}
        setEditEvent={setEditEvent}
      />
      <Toolbar
        setState={setState}
        selectedTime={state.selectedTime}
        selectedVisibleInfo={state.selectedVisibleInfo}
        cellHeight={state.cellHeight}
      />
      <Cal
        location_id={location_id}
        selectedTime={state.selectedTime}
        date={date}
        setEditEvent={setEditEvent}
        setAssignCalendar={setAssignCalendar}
        cellHeight={cellHeight}
        setAdhoc={setAdhoc}
        resources={resources}
        cal={cal}
        selectedVisibleInfo={state.selectedVisibleInfo}
      />
      {editEvent && (
        <EventDialog
          setEditEvent={setEditEvent}
          editEvent={editEvent}
          appointmentStatuses={appointmentStatuses}
          location_id={location_id}
        />
      )}
      {assignCalendar && (
        <AssignCalendarDialog
          setAssignCalendar={setAssignCalendar}
          assignCalendar={assignCalendar}
        />
      )}
    </>
  );
}
