import {
  withJsonFormsControlProps,
  withJsonFormsEnumProps,
  withJsonFormsOneOfEnumProps,
  withTranslateProps,
} from "@jsonforms/react";
import React, { useState } from "react";
import * as MaterialRenderers from "@jsonforms/material-renderers";

/**
 * @typedef {Object} Props
 * @property {Object} config - Render config
 * @property {Object} uischema - UI Schema for rendering
 * @property {string} errors - Error messages
 * @property {React.ComponentType} wrappedControl - Form control
 */

/**
 * Component ControlWithTouched
 * @param {Props} props
 */
function ControlWithTouched(props) {
  const [touched, setTouched] = useState(false);
  const WrappedControl = props.wrappedControl;
  return (
    <div onBlur={() => setTouched(true)}>
      <WrappedControl {...unwrapProps(props, touched)} />
    </div>
  );
}

/**
 * Unwraps props for wrappedControl.
 * @param {Props} props
 * @param {boolean} touched - Indicates, weather control was touched.
 * @returns {Object} Updated props for wrappedControl.
 */
function unwrapProps(props, touched) {
  const { errors, config, uischema } = props;
  const { forceShow } = { ...config, ...uischema.options };
  const showErrors = touched || forceShow;
  const result = { ...props, errors: showErrors ? errors : "" };
  delete result.wrappedControl;
  return result;
}

export const wrapperFunctions = {
  /**
   * @param {React.ComponentType} c
   * @returns {React.ComponentType}
   */
  MaterialEnumControl: (c) => withJsonFormsEnumProps(c, false),

  /**
   * @param {React.ComponentType} c
   * @returns {React.ComponentType}
   */
  MaterialOneOfEnumControl: (c) =>
    withJsonFormsOneOfEnumProps(withTranslateProps(React.memo(c)), false),

  /**
   * @param {React.ComponentType} c
   * @returns {React.ComponentType}
   */
  MaterialOneOfRadioGroupControl: (c) => withJsonFormsOneOfEnumProps(c),
};

/**
 * @typedef {Object} RendererRegistryEntry
 * @property {Function} tester - Tester function.
 * @property {Function} renderer - Renderer function.
 */

/**
 * Wraps renderer with additional functionality.
 * @param {RendererRegistryEntry} entry - Entry in renderers registry.
 * @returns {RendererRegistryEntry} Updated renderer entry.
 */
export function wrapRendererRegistry(entry) {
  const tester = entry.tester;
  let renderer = entry.renderer;
  Object.entries(MaterialRenderers.Unwrapped).some(([key, wrappedControl]) => {
    const otherTester = MaterialRenderers[`m${key.substring(1)}Tester`];
    const match = tester === otherTester;
    if (match) {
      const wrapperFunction =
        wrapperFunctions[key] || withJsonFormsControlProps;
      renderer = wrapperFunction((props) => (
        <ControlWithTouched {...{ ...props, wrappedControl }} />
      ));
    }
    return match;
  });
  return { renderer, tester };
}

export const materialRenderers =
  MaterialRenderers.materialRenderers.map(wrapRendererRegistry);
export const materialCells = MaterialRenderers.materialCells;
