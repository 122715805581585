import { JsonForms } from "@jsonforms/react";
import { materialRenderers } from "../jsonForms/materialRenderers.jsx";
import { materialCells } from "@jsonforms/material-renderers";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import TasksSelectInput from "./TasksSelectInput.jsx";
import Grid from "@mui/material/Grid2";
import { useWizardContext } from "./AppointmentWizardContext.jsx";
import AddIcon from "@mui/icons-material/Add";

const TaskFormComponent = ({ taskId, tasks, translation, formSubmitted }) => {
  const task = useMemo(
    () => tasks.short.find((t) => t.id === taskId),
    [taskId],
  );
  const { getFormPrefillData } = useWizardContext();

  // console.log(taskId, tasks, task);

  const initialData = useMemo(() => {
    if (!task?.template) return {};
    return getFormPrefillData(task.template);
  }, [task]);

  const [formState, setFormState] = useState(initialData);

  useEffect(() => {
    if (task?.template) {
      const prefillData = getFormPrefillData(task.template);
      setFormState((prev) => ({
        ...prev,
        ...prefillData,
      }));
    }
  }, [task, getFormPrefillData]);

  const handleFormChange = (data) => {
    setFormState(data);
  };

  return task ? (
    <JsonForms
      schema={task.template.schema}
      uischema={task.template.uischema}
      data={formState}
      renderers={materialRenderers}
      cells={materialCells}
      onChange={({ data }) => handleFormChange(data)}
      config={{ forceShow: formSubmitted }}
      i18n={{ translate: translation }}
    />
  ) : null;
};

const AppointmentTasks = ({ tasks, formSubmitted }) => {
  const { t, i18n } = useTranslation(["translation", "taskTemplates"]);

  const [selectedTasks, setSelectedTasks] = useState([""]);

  const handleChange = (e, index) => {
    setSelectedTasks(
      Object.assign([], selectedTasks, { [index]: e.target.value }),
    );
  };

  console.log(tasks);

  const translation = useMemo(
    () => (key, defaultMessage) => {
      // console.log(
      //   `Locale: ${i18n.language}, Key: ${key}, Default Message: ${defaultMessage}`,
      // );
      return i18n.exists(`taskTemplates:${key}`)
        ? t(`taskTemplates:${key}`)
        : defaultMessage;
    },
    [i18n.language],
  );

  return (
    <>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        {t("appointmentNotes.taskNotes")}
      </Typography>

      {selectedTasks.map((taskId, ix) => (
        <Box key={ix} mb="2">
          <Grid container spacing={2}>
            <Grid size={3} sx={{ display: "flex", alignItems: "center" }}>
              <TasksSelectInput
                tasks={tasks}
                value={taskId}
                labelKey="appointmentNotes.task"
                onChange={(e) => handleChange(e, ix)}
                key={ix}
              />
              {ix === selectedTasks.length - 1 && (
                <IconButton
                  aria-label="add task"
                  sx={{
                    ml: 2,
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "50%",
                    backgroundColor: "primary.main",
                    color: "white",
                    "&:hover": {
                      color: "primary.main",
                    },
                  }}
                  onClick={() => setSelectedTasks([...selectedTasks, ""])}
                >
                  <AddIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
          <Box mt={2}>
            <TaskFormComponent
              formSubmitted={formSubmitted}
              tasks={tasks}
              taskId={taskId}
              translation={translation}
            />
          </Box>
          <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
        </Box>
      ))}
    </>
  );
};

export default AppointmentTasks;
