import React from "react";
import {
  useLocation,
  useNavigate as useRrouterNavigate,
} from "react-router-dom";

/**
 * @param {string} base
 * @param {string} currentSearch
 * @param {string} pathString
 *
 * @returns {string}
 */
export function createUrlString(base, currentSearch, pathString) {
  const cs = new URLSearchParams(currentSearch);
  const location = cs.get("g_location");

  const newUrl = new URL(pathString, base);
  if (location != null && !newUrl.searchParams.has("g_location")) {
    newUrl.searchParams.set("g_location", location);
  }

  return `${newUrl.pathname}?${newUrl.searchParams.toString()}`;
}

export function useUrlStringCreator() {
  const origin = window.location.origin;
  const location = useLocation();

  return React.useCallback(
    (pathString) => {
      return createUrlString(origin, location.search, pathString);
    },
    [origin, location],
  );
}

export function useNavigate() {
  const navigate = useRrouterNavigate();
  const createUrlString = useUrlStringCreator();

  return React.useCallback(function (to, options) {
    navigate(createUrlString(to), options);
  });
}
