import { FormHelperText } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useActionData, useSubmit } from "react-router-dom";

/**
 * @returns {string[]}
 */
export function errorsFromApi({ t, result }) {
  const errors = result.errors;
  if (errors == null) {
    return [];
  }

  const res = errors
    .flatMap((e) => e.extensions?.errors)
    .filter((e) => e != null && e.code != null)
    .map((e) => t(`validation.${e.code}`));
  if (res.length !== 0) {
    return res;
  }

  return [t("validation.unknown")];
}

function FormError({ children }) {
  if (children == null || children.length == 0) {
    return null;
  }

  return <FormHelperText error={true}>{children}</FormHelperText>;
}

/**
 * @param {object} params
 * @param {string[]} params.formErrors
 */
export function FormErrorList({ formErrors }) {
  return (
    <FormError>
      {Object.values(formErrors).map((e, i) => (
        <span style={{ display: "block" }} key={i}>
          {e}
        </span>
      ))}
    </FormError>
  );
}

function flatToNested(data) {
  const res = {};

  Object.entries(data)
    .map(([kp, v]) => {
      return [kp.split("."), v];
    })
    .forEach(([kp, v]) => {
      const lastK = kp.pop();

      let currentObj = res;
      for (const k of kp) {
        currentObj[k] = currentObj[k] ?? {};
        currentObj = currentObj[k];
      }

      currentObj[lastK] = v;
    }, {});

  return res;
}

/**
 * @param {HTMLFormElement} formEl
 *
 * @returns {object}
 */
export function formData(formEl) {
  const namedElements = Array.from(formEl.elements).filter(
    (el) => el.name.length > 0,
  );

  const entries = namedElements.map((el) => {
    const className = el.className;
    if (
      className.match("MuiSelect") != null &&
      el.parentNode.querySelector(".MuiSelect-multiple") != null
    ) {
      return [el.name, el.value.length === 0 ? [] : el.value.split(",")];
    }

    switch (el.type) {
      case "number":
        return [el.name, el.valueAsNumber];
      case "checkbox":
        return [el.name, el.checked];
    }

    return [el.name, el.value];
  });

  return flatToNested(Object.fromEntries(entries));
}

/**
 * @param {object} opts
 * @param {Function} [opts.converter] Converts form data before submit.
 *
 * @returns {{errors: string[], onSubmit: (e: React.FormEventHandler<HTMLFormElement>) => void}}
 */
export function useRouterFormAction({ converter } = {}) {
  const { t } = useTranslation();
  const submit = useSubmit();
  const actionData = useActionData();
  const errors = errorsFromApi({ t, result: actionData ?? {} });

  const onSubmit = React.useCallback(
    function (e) {
      e.preventDefault();

      const conv = converter ?? ((x) => x);
      submit(conv(formData(e.currentTarget)), {
        method: "post",
        encType: "application/json",
      });
    },
    [submit, converter],
  );

  return { errors, onSubmit };
}

/**
 * @param {(e: React.FormEventHandler<HTMLFormElement>) => Promise<void>} mutationF
 * @param {() => void} onSuccess
 *
 * @returns {{loading: boolean, errors: string[], onSubmit: (e: React.FormEventHandler<HTMLFormElement>) => void}}
 */
export function useFormAction(mutationF, onSuccess) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const onSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      try {
        setLoading(true);
        const result = await mutationF(e);
        const errors = errorsFromApi({ result, t });
        setErrors(errors);
        if (errors.length === 0) {
          onSuccess();
        }
        setLoading(false);
      } catch (_e) {
        setLoading(false);
        setErrors([t("validation.unknown")]);
      }
    },
    [mutationF, onSuccess],
  );

  return { loading, errors, onSubmit };
}
