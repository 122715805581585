import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import theme from "./theme";
import { connectWS, disconnectWS } from "./utils/ws";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/cs";
import { useTranslation } from "react-i18next";
import { useAuthenticationToken } from "./context/AuthenticationTokenContext";

export function App() {
  const { i18n } = useTranslation();
  const [token] = useAuthenticationToken();
  useEffect(() => {
    if (token == null) {
      return;
    }

    connectWS();

    return function () {
      disconnectWS();
    };
  }, [token]);

  return (
    <StyledEngineProvider injectFirst={true}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={i18n.language}
        >
          <RouterProvider
            router={router}
            future={{
              v7_startTransition: true,
              v7_relativeSplatPath: true,
            }}
          />
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
