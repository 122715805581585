import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { updateInIfExists } from "shared/src/util.mjs";
import { useUrlStringCreator } from "../url";

/**
 * @param {import('react-router-dom').LinkProps} props
 */
const Link = React.forwardRef(function Link(props, ref) {
  const createUrlString = useUrlStringCreator();
  const updatedProps = updateInIfExists(props, ["to"], (to) =>
    createUrlString(to),
  );
  return <RouterLink ref={ref} {...updatedProps} />;
});

export default Link;
