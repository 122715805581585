import React from "react";
import { TablePagination } from "../components/table";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  IconButton,
} from "@mui/material";
import * as api from "../services/api";
import { useLoaderData } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Link from "../components/Link";

function parseInt(s, def = null) {
  if (s == null) {
    return def;
  }

  const res = window.parseInt(s, 10);
  if (Number.isNaN(res)) {
    return def;
  }

  return res;
}

function graphqlArgs(request) {
  const q = Object.fromEntries(new URL(request.url).searchParams.entries());

  const page = {
    limit: parseInt(q["page[limit]"], 10),
    offset: parseInt(q["page[offset]"], 0),
  };

  return { page };
}

export async function loader({ request }) {
  return { locations: await api.loadLocations(graphqlArgs(request)) };
}

export default function Locations() {
  const { t } = useTranslation();
  const loaderData = useLoaderData();
  const locationsConnection = loaderData.locations.data.locations;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t("locations.name")}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {locationsConnection.data.map((row) => (
          <TableRow key={row.id}>
            <TableCell>{row.name}</TableCell>
            <TableCell>
              <IconButton component={Link} to={`/locations/${row.id}/edit`}>
                &#9998;
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination connection={locationsConnection} />
        </TableRow>
      </TableFooter>
    </Table>
  );
}
